import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useSwiper } from 'swiper/react';
import { Children } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import arrow from "@assets/img/arrow.png"
import { breakpoints, colors, respondFrom } from '@styles';

import 'swiper/css';


interface SwiperWrapProps {
    children: React.ReactChild[];
    swiperOptions: SwiperProps;
}

const swiperCss = css`
    text-align: left;
    .swiper-slide {
        height: auto;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background:#FFFFFF;
            opacity: 0;
            transition: opacity 400ms ease;
        }
    }
    .swiper-slide:not(.swiper-slide-active):after {
        opacity: 0.9;
    }
    ${respondFrom(breakpoints.md, css`
        .swiper-slide-next:after {
            opacity: 0 !important;
        }
    `)}
`

const SwiperArrow = ({ left, right, onClick }: { left?: boolean, right?: boolean, onClick: (swiper: SwiperClass) => void }) => {
    const getIfHidden = () => !!left && swiper.isBeginning || !!right && swiper.isEnd
    const swiper = useSwiper();
    const [hidden, setHidden] = useState(getIfHidden())


    const styleSide = left ?
        css`left: 0; ${respondFrom(breakpoints.md, css`left: 62px;`)}` :
        css`right: 0px; ${respondFrom(breakpoints.md, css`right: 62px;`)}`;
    const swapArrow = left ? css`transform: scaleX(-1); margin-left: -3px;` : '';

    swiper.on('slideChange', () => {
        setHidden(getIfHidden());
    });

    const Arrow = styled.div`
        position: absolute;
        ${styleSide}
        width: 60px;
        height: 60px;
        line-height:60px;
        font-size: 0;
        border-radius: 37px;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        background-color: ${colors.ui.main};
        z-index: 2;
        cursor: pointer;
        user-select: none;
        ${respondFrom(breakpoints.md, css`width: 73px; height: 73px; line-height: 73px;`)}
        img {
            vertical-align: middle;
            margin-left: 3px;
            height: 16px;
            ${swapArrow}
            ${respondFrom(breakpoints.md, css`height: 19px;`)}
        }
        `

    if (!hidden) {
        return <Arrow onClick={() => onClick(swiper)}> <img src={arrow} /></Arrow>
    }
    return <></>
}

export default ({ children, swiperOptions, ...props }: SwiperWrapProps) => {
    const arrayChildren = Children.toArray(children);

    return (
        <>
            <Swiper css={swiperCss} {...swiperOptions} loop={false}>
                {Children.map(arrayChildren, (child, childIndex) => (
                    <SwiperSlide key={childIndex}>{child}</SwiperSlide>
                ))}

                <SwiperArrow left onClick={(swiper) => swiper.slidePrev()}></SwiperArrow>
                <SwiperArrow right onClick={(swiper) => swiper.slideNext()}></SwiperArrow>
            </Swiper>
        </>
    );
};
